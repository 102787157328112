<template>
    <div>
        <!-- 面包屑导航 -->
        <Breadcrumb :breadList="breadList">
        </Breadcrumb>
        <a-form-model class="formModal" ref="form" :rules="rules" :model="form" :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-form-model-item prop="name" label="自提点名称">
                <a-input v-model="form.name" placeholder="请输入内容" />
            </a-form-model-item>
            <a-form-model-item prop="addresseeTel" label="联系电话">
                <a-input v-model="form.addresseeTel" placeholder="请输入内容" />
            </a-form-model-item>
            <a-form-model-item prop="businessHours" label="提货时间">
                <a-input v-model="form.businessHours" placeholder="请输入内容" />
            </a-form-model-item>
            <a-form-model-item prop="photoImg" label="自提点提货">
                <ImageManage v-model="form.photoImg" @change="$refs.form.validateField(['photoImg'])" :square="false" :size="1024*1024*2" :showWidth="true">
                    <div slot="extra">请上传高清正面主图，提高用户辨识度和转化率，建议尺寸800*800像素，建议大小500KB以内。</div>
                </ImageManage>
                <!-- <a-upload list-type="picture-card" :file-list="fileList" @preview="handlePreview"
                    :action="$consts.uploadFileUrl" :headers="$store.state.header" :before-upload="beforeUpload"
                    @change="handleChange">
                    <div v-if="fileList.length === 0">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                            Upload
                        </div>
                    </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal> -->
                <!-- <div slot="extra"></div> -->
            </a-form-model-item>
            <a-form-model-item prop="provinceName" label="自提点地址">
                <a-cascader :options="options" placeholder="请选择省市区" v-model="selectedList" :fieldNames="{label:'name',value:'name',children:'childrens'}" @change="onChange" />
            </a-form-model-item>
            <a-form-model-item prop="address" label="地图定位">
                <a-input-search
                    v-model="form.address"
                    placeholder="请输入详细地址，并标记好地图位置"
                    enter-button="地图定位"
                    @search="onSearch"
                />
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <div style="width:100%;height:500px;">
                        <iframe :src="'/static/pages/map.html?params=' + mapPositionParams" frameborder="0" scrolling="no"
                            style="width:100%;height:100%;overflow:hidden;">
                        </iframe>
                </div>
                <br>
            </a-form-model-item>

            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button @click="$utils.linkTo('/shop/order/selfLiftingPoint')">
                    取消
                </a-button>
                <a-button :loading="addLoading" type="primary" @click="onSubmit" style="margin-left: 10px;">
                    保存
                </a-button>
                <br />
                <br />
                <br />
                <br />
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import ImageManage from '@/components/imageManage/index.vue'
// import BaiduMap from 'vue-baidu-map'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    Breadcrumb,
    ImageManage
  },
  data () {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      breadList: [
        {
          name: '订单管理',
          url: ''
        },
        {
          name: '自提点',
          changeParent: true,
          url: '/shop/order/selfLiftingPoint'
        },
        {
          name: '新增自提点',
          url: ''
        }
      ],
      addLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      previewVisible: false,
      previewImage: '',
      options: [], // 省市区三级
      selectedList: [], // 选择的省市 区
      fileList: [
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        // }
      ],
      mapPositionParams: encodeURIComponent(JSON.stringify({ type: 'new' })),
      form: {
        id: undefined,
        name: '',
        addresseeTel: '',
        photoImg: '',
        businessHours: '',
        address: '',
        longitude: '',
        latitude: '',
        provinceName: '',
        cityName: '',
        districtName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        businessHours: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        photoImg: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        provinceName: [
          {
            required: true,
            message: '省市区不能为空',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入详情地址',
            trigger: 'blur'
          }
        ],
        addresseeTel: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            pattern: this.$consts.phoneReg,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async created () {
    const id = this.$route.query.id
    this.form.id = id

    const res = await this.$store.dispatch('http', {
      api: 'getProvinceCityCounty'
    })
    if (res) {
      this.options = res
    }

    if (id) {
      const res = await this.$store.dispatch('http', {
        api: 'selfLiftingPointInfo',
        query: { id },
        error: () => {
          console.log('出错了')
        }
      })
      if (res) {
        // 数据回显
        const { name, address, addresseeTel, businessHours, cityName, districtName, photoImg, provinceName, longitude, latitude } = res
        this.form.name = name
        this.form.address = address
        this.form.addresseeTel = addresseeTel
        this.form.businessHours = businessHours
        this.form.cityName = cityName
        this.form.districtName = districtName
        this.form.photoImg = photoImg
        this.form.provinceName = provinceName
        this.form.longitude = longitude
        this.form.latitude = latitude

        // 图片回显
        // this.fileList = [{
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: this.form.photoImg
        // }]
        // 级联列表回显
        this.selectedList = [this.form.provinceName, this.form.cityName, this.form.districtName]

        // 地图标记点回显
        let mapObj = {}
        mapObj.type = 'edit'
        mapObj.lng = this.form.longitude
        mapObj.lat = this.form.latitude
        this.mapPositionParams = encodeURIComponent(JSON.stringify(mapObj))
      }
    }
  },
  computed: {
    mapHtml () {
      return process.env.VUE_APP_STATIC + '/map.html'
    }
  },
  mounted () {
    const callback = (lng, lat) => {
      this.form.longitude = lng
      this.form.latitude = lat
    }
    window.addEventListener('message', function (e) { // 监听 message 事件
      if (e.data === 'addPointer') {
        let lngLat = localStorage.getItem('BMap_address').split(',')
        let lng = lngLat[0]
        let lat = lngLat[1]
        if (lng && lat) {
          callback(lng, lat)
        }
      }
    })
  },
  methods: {
    onResourceChange (e) {
      console.log(e, '选中了多少图片')
    },
    onSearch (e) {
      console.log(e)
      if (!e) {
        this.$refs.form.validateField(['provinceName', 'address'])
        return
      }
      let obj = {}
      obj.type = 'search'
      obj.search = this.form.provinceName + this.form.cityName + this.form.districtName + this.form.address
      this.mapPositionParams = encodeURIComponent(JSON.stringify(obj))
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 116.404
      this.center.lat = 39.915
      this.zoom = 15
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' // 这里获取上传文件的类型
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '图片格式不正确'
          })
          return reject(new Error(0)) // 如有问题那咱们通过reject 函数传一个false阻止他的上传
        }
        if (isJpgOrPng) {
          let reader = new FileReader() // 这个是通过原生的方式来获取图片的宽高
          reader.readAsDataURL(file)
          reader.onload = () => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => {
              //   let w = image.width
              //   let h = image.height
              //   if (w !== h) {
              //     this.$store.dispatch('showToast', {
              //       type: 'error',
              //       message: '请上传1:1的图片'
              //     })
              //     return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              //   }
              if (file.size > 500 * 1024) { // 102400
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '图片大小不能超过500K,建议200k以内'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else {
                return resolve(true) // 如没有问题了那咱们就通过resolve函数添加一个true 让文件上传
              }
            }
          }
        }
      })
    },
    onChange (e) {
      this.form.provinceName = e[0]
      this.form.cityName = e[1]
      this.form.districtName = e[2]
      this.selectedList = e
    },
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // handleChange ({ fileList }) {
    //   this.fileList = fileList
    //   console.log(this.fileList, 'this file list')
    //   if (this.fileList && this.fileList[0] && this.fileList[0].status === 'done') {
    //     this.form.photoImg = this.fileList[0].response.result
    //   }
    //   if (this.fileList.length === 0) {
    //     this.form.photoImg = ''
    //   }
    //   this.$refs.form.validateField(['photoImg'])
    // },
    onSubmit () {
      this.addLoading = true
      setTimeout(() => {
        this.addLoading = false
      }, 1000)
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.form.latitude !== '' && this.form.longitude !== '') {
            let params = this.$utils.clone(this.form)
            let apiString = this.form.id ? 'selfLiftingPointEdit' : 'selfLiftingPointAdd'
            const res = await this.$store.dispatch('http', {
              api: apiString,
              params: params,
              error: (e) => {
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: e
                })
              }
            })
            if (res) {
              this.$store.dispatch('showToast', {
                type: 'success',
                message: '自提点添加成功'
              })
              setTimeout(() => {
                this.$utils.linkTo('/shop/order/selfLiftingPoint')
              }, 1000)
            }
          } else {
            this.$store.dispatch('showToast', {
              type: 'error',
              message: '请在地图中添加定位点'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.toolTip {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tips {
    color: rgba(24, 144, 255, 1);
    background: rgba(230, 247, 255, 1);
    padding: 10px 50px;
    border: 1px solid rgb(215, 231, 247);
    margin-top: 10px;

    :first-child {
        font-weight: bold;
        font-size: 20px;
    }
}

.bm-view {
    width:100%;
    height:100%;
}
</style>
<style>
* { touch-action: pan-y; }
</style>
